.artikelbox {
    width: 80%;
    margin-left: 10%;
    transition: all 0.2s linear;

}
.artikelContainer {
    width: 100%;
    height: 100%;
    border-radius: 1rem 1rem 0rem 0rem;
    transition: all 0.2s linear;
}

.image1 {
    width: 100%;
    height: 100%;
    border-radius: 1rem 1rem 0rem 0rem;
}
.headerBlock {

    font-size: 12px;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.8s linear;
    border-radius: 0rem 0rem 1rem 1rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

}
.pfeilbox {
    width: 5rem;
    height: 2rem;
    padding: 0.5rem;
    border-radius: 1rem;
    transition: all 0.4s linear;
    font-size: 20px;
    border: none;
    background-color: #ececec;
    font-family: Consolas;
    font-size: 10px;
    text-decoration: none;

}
.pfeilbox:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.headerBlock2 {

    font-size: 12px;
    width: 100%;
    transition: all 0.8s linear;
    display: flex;
    align-items: center;
    justify-content: center;

}

.contentBlock {

    font-size: 12px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.8s linear;
    border-radius: 0rem 0rem 1rem 1rem;
    padding: 1rem;


}
.contentBlock:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.contentBlock2 {

    font-size: 12px;
    width: 100%;
    transition: all 0.8s linear;
    padding-top: 1rem;

}

.text {
    display: flex;
    text-align: center;
    font-size: 14px;
}

.dividerButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
}

.divider1 {
    width: 50%;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 0.05rem;
    background-color: #555555;
}


