.box2 {
    width: 80%;
    height: 100%;
    border-radius: 2rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.2s linear;
    margin: 1rem;
    max-width: 30rem;


}
.box2:hover {

    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);

}
.box1 {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.img1 {
    width: 3rem;
    height: 3rem;
    margin: 1.5rem;
}
.img2 {
    width: 3rem;
    height: 3rem;
    margin: 1.5rem;
}
.vs {
    font-size: 20px;
    margin-top: 2.5rem;
}

.devider {
    width: 80%;
    height: 0.1rem;
    background-color: #555555;
    margin-left: 10%;
}
.box {
    display: flex;
    justify-content: center;
}

.pGame {
    padding: 1rem;
    text-align: center;
    height: 100%;
    font-size: 12px;
    background-color: #ffffff;
    border-radius: 0rem 0rem 1rem 1rem;
}