.p {
    padding: 1rem;
    text-align: center;
    height: 100%;
    background-color: #ffffff;
    border-radius: 0rem 0rem 1rem 1rem;
}
.p1 {
    padding: 1rem;
    text-align: center;
    height: 100%;
    background-color: #ffffff;
    border-radius: 0rem 0rem 1rem 1rem;
}
.line {
    top: 0;
    height: 0.1rem;
    width: 100%;
    background-color: #565656;
    margin-top: 0.7rem;
}
.p2 {
    padding: 1rem;
    text-align: center;
    height: 100%;
    background-color: #ffffff;
    border-radius: 0rem 0rem 1rem 1rem;
}
.line1 {
    top: 0;
    height: 0.03rem;
    width: 100%;
    background-color: #565656;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}
