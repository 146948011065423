.dialog {
    position: fixed;
    width: 80%;
    background-color: #ffffff;
    border-radius: 15px;
    border: none;
    z-index: 10000000;
    left: 10%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.button12 {
    position: relative;
    left: 35%;
    right: 35%;
    top: 1rem;
    width: 30%;
    height: 3rem;
    border: none;
    border-radius: 25px;
    font-family: Consolas;
    color: #555;
    cursor: auto;
}
.button12:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.dialogContent {
    align-items: center;
    margin-top: 2rem;
    margin-left: 1rem;
    padding-right: 2rem;
    width: 100%;
}
.input {
    border: 0.5px solid grey;
    height: 3rem;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;
    font-family: Consolas;
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 15px;

}
.input:active {
    border: 0.7px solid grey;
}

.buttonBestellen {

    background-color: #3434e3;
    position: relative;
    width: 100%;
    height: 3rem;
    border: none;
    padding: 10px 25px;
    border-radius: 25px;
    font-family: Consolas;
    color: #ffffff;
    cursor: auto;
    margin-top: 30px;
    margin-bottom: 20px;
}
.buttonBestellen:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}